import React from 'react';
import { Strings } from './Strings';
import { currentDate, generateRandomString } from '../../../../utils/general';
import { Form, Row, Col } from 'react-bootstrap';

const handleFileUpload = (e, field, fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading, callback) => {

    setFileUploading(true);

    let file = e.target.files[0]; // FileList object
    let fileSize = file.size / 1024 / 1024; // in MiB

    if (fileSize > 1) {
        alert('Sorry, this file is too big (max 1MB)! Please move on to the next question. You can manually email this document to the SABMR after completing your form.');
        setFileUploading(false);
        e.target.value = null;
        return;
    }
    
     // Only process image files.
    //  if (!file.type.match('image.*')) {
    //     continue;
    //   }

    setTimeout(() => {
        
        let reader = new FileReader();

        // Closure to capture the file information.
        reader.onload = ( () => {
            return function(e) {
                setFileUploading(false);
                // console.log('----------> result: ');
                // console.log(e.target.result);

                field.answer['upload'] = e.target.result;
                setFields({...fields, field});

                callback && callback(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
            };
        })(file);

        // Read in the image file as a data URL.
        reader.readAsDataURL(file);

    }, 0); // 5000 for testing
}

export const calculateBmi = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
        
    let field = fields[3];

    const weight = fields[1].answer;
    const height = fields[2].answer;

    const BMI = ( weight / (height * height) ).toFixed(2);

    field.answer = BMI;
    setFields({...fields, field});

    if( weight >= 49 && BMI <= 40 ){ // Step fail (weight less than or equal 49kg or bmi more than 40)
    //if(BMI <= 41){
        // Passed BMI
        // Update answer in our state;
        setActiveField(activeField +1);
    }
    else {
        // Failed BMI
        setRegret(true);
    }
}

export const asthmaDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {

    // Sanity check
    if(Questions[23].function !== 'asthmaDetails'){
        console.log('Question with index 23 does not match the asthmaDetails function!');
        return;
    }
    
    let field = fields[23];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Date of last asthma attack</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={field.answer['Date of last asthma attack'] ? field.answer['Date of last asthma attack'] : '' }
                    onChange={ (e) => {
                        field.answer['Date of last asthma attack'] = e.target.value;
                        setFields({...fields, field});
                        asthmaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>
            
            <p>
                <Form.Label>Current Medication</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Current Medication'] ? field.answer['Current Medication'] : '' }  onChange={ (e) => {
                    field.answer['Current Medication'] = e.target.value;
                    setFields({...fields, field});
                    asthmaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Duration of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Duration of condition'] ? field.answer['Duration of condition'] : '' }  onChange={ (e) => {
                    field.answer['Duration of condition'] = e.target.value;
                    setFields({...fields, field});
                    asthmaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );

}

export const kidneyDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[25].function !== 'kidneyDetails'){
        console.log('Question with index 25 does not match the kidneyDetails function!');
        return;
    }
    
    let field = fields[25];

    setCustomHTML(
        <div>
            
            <div>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </div>

            <div>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </div>

            <div>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </div>

            <div>
                <Form.Label>Have you ever been considered for a kidney dialysis program?</Form.Label>
                <Row>
                    {
                        ['Yes', 'No'].map(option => (
                            <div key={option} className="col-3">
                                <Form.Check inline type="radio">
                                    <Form.Check.Input name={generateRandomString(15)} id={`ankles_${option}`} value={option} checked={ field.answer['Have you ever been considered for a kidney dialysis program?']['answer'] === option ? true : false } onChange={ (e) => {
                                        field.answer['Have you ever been considered for a kidney dialysis program?']['answer'] = e.target.value;
                                        setFields({...fields, field});
                                        kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                    } } />
                                    <Form.Check.Label htmlFor={`ankles_${option}`} className="pl-1" >{`${option}`}</Form.Check.Label>
                                </Form.Check>
                            </div>
                        ))
                    }
                </Row>

                {
                    (field.answer['Have you ever been considered for a kidney dialysis program?']['answer'] === 'Yes') &&
                    <div>
                        <Form.Label>Please specify</Form.Label>
                        <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Have you ever been considered for a kidney dialysis program?']['detail'] ? field.answer['Have you ever been considered for a kidney dialysis program?']['detail'] : '' }  onChange={ (e) => {
                            field.answer['Have you ever been considered for a kidney dialysis program?']['detail'] = e.target.value;
                            setFields({...fields, field});
                            kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } } />
                    </div>
                }

            </div>

            <div>
                <Form.Label>Do your ankles swell up at the end of the day?</Form.Label>
                <Row>
                    {
                        ['Yes', 'No'].map(option => (
                            <div key={option} className="col-3">
                                <Form.Check inline type="radio">
                                    <Form.Check.Input name={generateRandomString(15)} id={`ankles_${option}`} value={option} checked={ field.answer['Do your ankles swell up at the end of the day?']['answer'] === option ? true : false } onChange={ (e) => {
                                        field.answer['Do your ankles swell up at the end of the day?']['answer'] = e.target.value;
                                        setFields({...fields, field});
                                        kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                    } } />
                                    <Form.Check.Label htmlFor={`ankles_${option}`} className="pl-1" >{`${option}`}</Form.Check.Label>
                                </Form.Check>
                            </div>
                        ))
                    }
                </Row>

                {
                    (field.answer['Do your ankles swell up at the end of the day?']['answer'] === 'Yes') &&
                    <div>
                        <Form.Label>Please specify</Form.Label>
                        <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Do your ankles swell up at the end of the day?']['detail'] ? field.answer['Do your ankles swell up at the end of the day?']['detail'] : '' }  onChange={ (e) => {
                            field.answer['Do your ankles swell up at the end of the day?']['detail'] = e.target.value;
                            setFields({...fields, field});
                            kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } } />
                    </div>
                }

            </div>

            <div>
                <Form.Label>Do you get up more than once a night to pass urine?</Form.Label>
                <Row>
                    {
                        ['Yes', 'No'].map(option => (
                            <div key={option} className="col-3">
                                <Form.Check inline type="radio">
                                    <Form.Check.Input name={generateRandomString(15)} id={`urine_${option}`} value={option} checked={ field.answer['Do you get up more than once a night to pass urine?']['answer'] === option ? true : false } onChange={ (e) => {
                                        field.answer['Do you get up more than once a night to pass urine?']['answer'] = e.target.value;
                                        setFields({...fields, field});
                                        kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                    } } />
                                    <Form.Check.Label htmlFor={`urine_${option}`} className="pl-1" >{`${option}`}</Form.Check.Label>
                                </Form.Check>
                            </div>
                        ))
                    }
                </Row>

                {
                    (field.answer['Do you get up more than once a night to pass urine?']['answer'] === 'Yes') &&
                    <div>
                        <Form.Label>Please specify</Form.Label>
                        <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Do you get up more than once a night to pass urine?']['detail'] ? field.answer['Do you get up more than once a night to pass urine?']['detail'] : '' }  onChange={ (e) => {
                            field.answer['Do you get up more than once a night to pass urine?']['detail'] = e.target.value;
                            setFields({...fields, field});
                            kidneyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } } />
                    </div>
                }

            </div>
            
        </div>
    );

}

export const intestinalProblemsDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {

    // Sanity check
    if(Questions[26].function !== 'intestinalProblemsDetails'){
        console.log('Question with index 26 does not match the intestinalProblemsDetails function!');
        return;
    }
    
    let field = fields[26];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    intestinalProblemsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' } 
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        intestinalProblemsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    intestinalProblemsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );

}

export const thyroidDiseaseDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {

    // Sanity check
    if(Questions[27].function !== 'thyroidDiseaseDetails'){
        console.log('Question with index 27 does not match the thyroidDiseaseDetails function!');
        return;
    }
    
    let field = fields[27];

    setCustomHTML(
        <div>
            <p>Kindly note that for any thyroid condition the SABMR requirement is an accompanying copy of a recent thyroid antibody test not older than three months. (NOT thyroid function).</p>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    thyroidDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        thyroidDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    thyroidDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            <p>&nbsp;</p>

            <div class="upload-field">
                <Row>
                    <Col sm={12} lg={8}>

                        <Form.Label>Recent thyroid antibody test</Form.Label>
                        <input type="file" onChange={ (e) => {
                            handleFileUpload(e, field, fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading, thyroidDiseaseDetails);
                        } }/>

                    </Col>
                    <Col sm={12} lg={4}>
                        {
                            field.answer.upload &&
                            <div>
                                <svg class="svg-icon check" viewBox="0 0 20 20">
                                    <path fill="none" d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"></path>
                                </svg>
                            </div>
                        }
                    </Col>
                </Row>
                
            </div>

        </div>
    );

}

export const bloodDiseaseDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {

    // Sanity check
    if(Questions[28].function !== 'bloodDiseaseDetails'){
        console.log('Question with index 28 does not match the bloodDiseaseDetails function!');
        return;
    }
    
    let field = fields[28];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    bloodDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control 
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' } 
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        bloodDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    bloodDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );

}

export const geneticDiseaseDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {

    // Sanity check
    if(Questions[29].function !== 'geneticDiseaseDetails'){
        console.log('Question with index 29 does not match the geneticDiseaseDetails function!');
        return;
    }
    
    let field = fields[29];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    geneticDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        geneticDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    geneticDiseaseDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );

}

export const malariaDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[31].function !== 'malariaDetails'){
        console.log('Question with index 31 does not match the malariaDetails function!');
        return;
    }
    
    let field = fields[31];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    malariaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control 
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' } 
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        malariaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    malariaDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const operationsDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[33].function !== 'operationsDetails'){
        console.log('Question with index 33 does not match the operationsDetails function!');
        return;
    }
    
    let field = fields[33];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    operationsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        operationsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    operationsDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const hepatitisEtcDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[37].function !== 'hepatitisEtcDetails'){
        console.log('Question with index 37 does not match the hepatitisEtcDetails function!');
        return;
    }
    
    let field = fields[37];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    hepatitisEtcDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        hepatitisEtcDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    hepatitisEtcDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const growthHormoneDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[39].function !== 'growthHormoneDetails'){
        console.log('Question with index 39 does not match the growthHormoneDetails function!');
        return;
    }
    
    let field = fields[39];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    growthHormoneDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)} 
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' } 
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        growthHormoneDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    growthHormoneDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const chronicMedicationDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[41].function !== 'chronicMedicationDetails'){
        console.log('Question with index 41 does not match the chronicMedicationDetails function!');
        return;
    }
    
    let field = fields[41];

    setCustomHTML(
        <div>

            <p>
                <Form.Label>Please provide list of chronic medications<br />
                    <small>including dosage and duration of treatment(s)</small>
                </Form.Label>
                <Form.Control name={generateRandomString(15)} as="textarea" rows="9" value={ field.answer['Chronic medications'] ? field.answer['Chronic medications'] : '' }  onChange={ (e) => {
                    field.answer['Chronic medications'] = e.target.value;
                    setFields({...fields, field});
                    chronicMedicationDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

        </div>
    );
}

export const nonPrescriptionDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[43].function !== 'nonPrescriptionDetails'){
        console.log('Question with index 43 does not match the nonPrescriptionDetails function!');
        return;
    }
    
    let field = fields[43];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    nonPrescriptionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        nonPrescriptionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    nonPrescriptionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const migrainesDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[45].function !== 'migrainesDetails'){
        console.log('Question with index 45 does not match the migrainesDetails function!');
        return;
    }
    
    let field = fields[45];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    migrainesDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' }
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        migrainesDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    migrainesDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const medicalConditionDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[47].function !== 'medicalConditionDetails'){
        console.log('Question with index 47 does not match the medicalConditionDetails function!');
        return;
    }
    
    let field = fields[47];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    medicalConditionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Last date of treatment</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='date'
                    autoComplete="off"
                    max={currentDate}
                    value={ field.answer['Last date of treatment'] ? field.answer['Last date of treatment'] : '' } 
                    onChange={ (e) => {
                        field.answer['Last date of treatment'] = e.target.value;
                        setFields({...fields, field});
                        medicalConditionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } } 
                />
            </p>

            <p>
                <Form.Label>Duration of treatment</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Duration of treatment'] ? field.answer['Duration of treatment'] : '' }  onChange={ (e) => {
                    field.answer['Duration of treatment'] = e.target.value;
                    setFields({...fields, field});
                    medicalConditionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const bloodTransfusionDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[49].function !== 'bloodTransfusionDetails'){
        console.log('Question with index 49 does not match the bloodTransfusionDetails function!');
        return;
    }
    
    let field = fields[49];

    setCustomHTML(
        <div>
            <p>
                <Form.Label>Details of condition</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Details of condition'] ? field.answer['Details of condition'] : '' }  onChange={ (e) => {
                    field.answer['Details of condition'] = e.target.value;
                    setFields({...fields, field});
                    bloodTransfusionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Country of transfusion</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Country of transfusion'] ? field.answer['Country of transfusion'] : '' }  onChange={ (e) => {
                    field.answer['Country of transfusion'] = e.target.value;
                    setFields({...fields, field});
                    bloodTransfusionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Year of transfusion</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={field.answer['Year of transfusion'] ? field.answer['Year of transfusion'] : '' }  onChange={ (e) => {
                    field.answer['Year of transfusion'] = e.target.value;
                    setFields({...fields, field});
                    bloodTransfusionDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
        </div>
    );
}

export const allergyDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[50].function !== 'allergyDetails'){
        console.log('Question with index 50 does not match the allergyDetails function!');
        return;
    }
    
    let field = fields[50];

    setCustomHTML(
        <div>
            <Row>
                {
                    ['Yes', 'No'].map(option => (
                        <div key={option} className="col-3">
                            <Form.Check inline type="radio">
                                <Form.Check.Input 
                                    id={`allergyDetails_${option}`}
                                    name={generateRandomString(15)}
                                    value={option}
                                    checked={ field.answer['answer'] === option ? true : false }
                                    onChange={ (e) => {
                                        field.answer['answer'] = e.target.value;
                                        field.validation['detail'] = e.target.value === 'Yes' ? '' : 'none'; // change validation for detail field based on the answer field
                                        setFields({...fields, field});
                                        allergyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                    }}
                                />
                                <Form.Check.Label htmlFor={`allergyDetails_${option}`} className="pl-1" >{`${option}`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    ))
                }
            </Row>

            {
                (field.answer['answer'] === 'Yes') &&
                <div>
                    <Form.Label>Please specify</Form.Label>
                    <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['detail'] ? field.answer['detail'] : '' }  onChange={ (e) => {
                        field.answer['detail'] = e.target.value;
                        setFields({...fields, field});
                        allergyDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } } />
                </div>
            }
        </div>
    );
}

export const refusedMedicalDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[51].function !== 'refusedMedicalDetails'){
        console.log('Question with index 51 does not match the refusedMedicalDetails function!');
        return;
    }
    
    let field = fields[51];

    setCustomHTML(
        <div>
            <Row>
                {
                    ['Yes', 'No'].map(option => (
                        <div key={option} className="col-3">
                            <Form.Check inline type="radio">
                                <Form.Check.Input
                                    id={`refused_${option}`}
                                    name={generateRandomString(15)}
                                    value={option}
                                    checked={ field.answer['answer'] === option ? true : false }
                                    onChange={ (e) => {
                                        field.answer['answer'] = e.target.value;
                                        field.validation['detail'] = e.target.value === 'Yes' ? '' : 'none'; // change validation for detail field based on the answer field
                                        setFields({...fields, field});
                                        refusedMedicalDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                    } }
                                />
                                <Form.Check.Label htmlFor={`refused_${option}`} className="pl-1" >{`${option}`}</Form.Check.Label>
                            </Form.Check>
                        </div>
                    ))
                }
            </Row>

            {
                (field.answer['answer'] === 'Yes') &&
                <div>
                    <Form.Label>Please specify</Form.Label>
                    <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['detail'] ? field.answer['detail'] : '' }  onChange={ (e) => {
                        field.answer['detail'] = e.target.value;
                        setFields({...fields, field});
                        refusedMedicalDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } } />
                </div>
            }
        </div>
    );
}

export const bloodDonorDetails = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[53].function !== 'bloodDonorDetails'){
        console.log('Question with index 53 does not match the bloodDonorDetails function!');
        return;
    }
    
    let field = fields[53];

    setCustomHTML(
        <div>
            <p>Applicants are welcome to provide this information at a later stage if not available. The fields below are not compulsory.</p>
            <p>
                <Form.Label>Card No.:</Form.Label>
                <Form.Control
                    name={generateRandomString(15)}
                    type='text'
                    autoComplete="off"
                    value={ field.answer['card number'] ? field.answer['card number'] : '' }
                    onChange={ (e) => {
                        field.answer['card number'] = e.target.value;
                        setFields({...fields, field});
                        bloodDonorDetails(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                    } }
                />
            </p>

            <p>&nbsp;</p>

            <div class="upload-field">
                <Row>
                    <Col sm={12} lg={12}>
                        <Form.Label>Please upload scanned copy of blood donor card</Form.Label>
                    </Col>
                    
                    <Col sm={12} lg={8}>
                        <input type="file" name={generateRandomString(15)} onChange={ (e) => {
                            handleFileUpload(e, field, fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading, bloodDonorDetails);
                        } }/>

                    </Col>
                    <Col sm={12} lg={4}>
                        {
                            field.answer.upload &&
                            <div>
                                <svg class="svg-icon check" viewBox="0 0 20 20">
                                    <path fill="none" d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"></path>
                                </svg>
                            </div>
                        }
                    </Col>
                </Row>
                
            </div>

        </div>
    );
}

export const additionalPersonalInformation = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[61].function !== 'additionalPersonalInformation'){
        console.log('Question with index 61 does not match the additionalPersonalInformation function!');
        return;
    }
    
    let field = fields[61];

    setCustomHTML(
        <div>

            <p>
                <Form.Label>Postal Address</Form.Label>
                <Form.Control name={generateRandomString(15)} as="textarea" rows="4" value={ field.answer['Postal Address'] ? field.answer['Postal Address'] : '' }  onChange={ (e) => {
                    field.answer['Postal Address'] = e.target.value;
                    setFields({...fields, field});
                    additionalPersonalInformation(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Postal Code'] ? field.answer['Postal Code'] : '' }  onChange={ (e) => {
                    field.answer['Postal Code'] = e.target.value;
                    setFields({...fields, field});
                    additionalPersonalInformation(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Mobile Number'] ? field.answer['Mobile Number'] : '' }  onChange={ (e) => {
                    field.answer['Mobile Number'] = e.target.value;
                    setFields({...fields, field});
                    additionalPersonalInformation(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Telephone Number (Home)</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Telephone Number (Home)'] ? field.answer['Telephone Number (Home)'] : '' }  onChange={ (e) => {
                    field.answer['Telephone Number (Home)'] = e.target.value;
                    setFields({...fields, field});
                    additionalPersonalInformation(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Telephone Number (Business)</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Telephone Number (Business)'] ? field.answer['Telephone Number (Business)'] : field.answer['Telephone Number (Business)'] }  onChange={ (e) => {
                    field.answer['Telephone Number (Business)'] = e.target.value;
                    setFields({...fields, field});
                    additionalPersonalInformation(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>           

        </div>
    );
}

export const alternativeContactInformationPrimary = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[62].function !== 'alternativeContactInformationPrimary'){
        console.log('Question with index 62 does not match the alternativeContactInformationPrimary function!');
        return;
    }
    
    let field = fields[62];

    setCustomHTML(
        <div>

            <p>
                <Form.Label>First Name</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['First Name'] ? field.answer['First Name'] : '' }  onChange={ (e) => {
                    field.answer['First Name'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Last Name'] ? field.answer['Last Name'] : '' }  onChange={ (e) => {
                    field.answer['Last Name'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Type of Relationship</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Type of Relationship'] ? field.answer['Type of Relationship'] : '' }  onChange={ (e) => {
                    field.answer['Type of Relationship'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Mobile Number'] ? field.answer['Mobile Number'] : '' }  onChange={ (e) => {
                    field.answer['Mobile Number'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Telephone Number (Home)</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Telephone Number (Home)'] ? field.answer['Telephone Number (Home)'] : '' }  onChange={ (e) => {
                    field.answer['Telephone Number (Home)'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Email Address</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Email Address'] ? field.answer['Email Address'] : '' }  onChange={ (e) => {
                    field.answer['Email Address'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>  

            <p>
                <Form.Label>Postal Address</Form.Label>
                <Form.Control name={generateRandomString(15)} as="textarea" rows="4" value={ field.answer['Postal Address'] ? field.answer['Postal Address'] : '' }  onChange={ (e) => {
                    field.answer['Postal Address'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Postal Code'] ? field.answer['Postal Code'] : '' }  onChange={ (e) => {
                    field.answer['Postal Code'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationPrimary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>         

        </div>
    );
}

export const alternativeContactInformationSecondary = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[63].function !== 'alternativeContactInformationSecondary'){
        console.log('Question with index 63 does not match the alternativeContactInformationSecondary function!');
        return;
    }
    
    let field = fields[63];

    setCustomHTML(
        <div>

            <p>
                <Form.Label>First Name</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['First Name'] ? field.answer['First Name'] : '' }  onChange={ (e) => {
                    field.answer['First Name'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Last Name</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Last Name'] ? field.answer['Last Name'] : '' }  onChange={ (e) => {
                    field.answer['Last Name'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Type of Relationship</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Type of Relationship'] ? field.answer['Type of Relationship'] : '' }  onChange={ (e) => {
                    field.answer['Type of Relationship'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Mobile Number'] ? field.answer['Mobile Number'] : '' }  onChange={ (e) => {
                    field.answer['Mobile Number'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>
            
            <p>
                <Form.Label>Telephone Number (Home)</Form.Label>
                <Form.Control name={generateRandomString(15)} type='number' autoComplete="off" value={ field.answer['Telephone Number (Home)'] ? field.answer['Telephone Number (Home)'] : '' }  onChange={ (e) => {
                    field.answer['Telephone Number (Home)'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Email Address</Form.Label>
                <Form.Control name={generateRandomString(15)} type='text' autoComplete="off" value={ field.answer['Email Address'] ? field.answer['Email Address'] : '' }  onChange={ (e) => {
                    field.answer['Email Address'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>  

            <p>
                <Form.Label>Postal Address</Form.Label>
                <Form.Control name={generateRandomString(15)} as="textarea" rows="4" value={ field.answer['Postal Address'] ? field.answer['Postal Address'] : '' }  onChange={ (e) => {
                    field.answer['Postal Address'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>

            <p>
                <Form.Label>Postal Code</Form.Label>
                <Form.Control name={generateRandomString(15)}  type='number' autoComplete="off" value={ field.answer['Postal Code'] ? field.answer['Postal Code'] : '' }  onChange={ (e) => {
                    field.answer['Postal Code'] = e.target.value;
                    setFields({...fields, field});
                    alternativeContactInformationSecondary(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                } } />
            </p>         

        </div>
    );
}

export const digitalSignature = (fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading) => {
    
    // Sanity check
    if(Questions[66].function !== 'digitalSignature'){
        console.log('Question with index 66 does not match the digitalSignature function!');
        return;
    }
    
    let field = fields[66];

    const checkboxQuestions = [
        'I confirm that I would like to Register as a blood stem cell donor',
        'I confirm that I would like to receive a SABMR Donor Card as proof of registration',
        'I agree to receiving marketing and general information and communication from SABMR',
        'I agree to receiving newsletter from SABMR'
    ];

    setCustomHTML(
        <div>

            <p>Terms & Conditions</p>
            <hr />

            <div className="termsConditions" 
                dangerouslySetInnerHTML={{__html: Strings.termsAndConditions}}
            />
            
            <p>&nbsp;</p>

            <Row>
                {
                    checkboxQuestions.map( (question) => {

                        return ['Yes'].map(option => (
                            <div key={option} className="col-12">
                                <Form.Check inline type="checkbox">
                                    <Form.Check.Input 
                                        id={question}
                                        name={generateRandomString(15)}
                                        value={option} 
                                        checked={ field.answer[question] === 'Yes' ? true : false }
                                        onChange={ (e) => {
                                            field.answer[question] = field.answer[question] === 'Yes' ? '' : 'Yes';
                                            setFields({...fields, field});
                                            digitalSignature(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                                        } }
                                    />
                                    <Form.Check.Label htmlFor={question} className="pl-1" ><small>{`${question}`}</small> { question === 'I confirm that I would like to Register as a blood stem cell donor' ? <small style={{color: 'red'}}>(required)</small> : '' }</Form.Check.Label>
                                </Form.Check>
                            </div>
                        ))
                    })
                }
            </Row>
            
            <p>&nbsp;</p>
            <p>Digital Signature</p>
            <hr />

            <p><small><strong>I HEREBY WAIVE ANY CLAIM OF WHATSOEVER NATURE I MAY HAVE AGAINST THE SOUTH AFRICAN BONE MARROW REGISTRY IN RESPECT OF ANY UNAUTHORISED DISCLOSURE OF MY PERSONAL INFORMATION WHETHER AS A RESULT OF ACTIONS OR OMISSIONS OF THE SOUTH AFRICAN BONE MARROW REGISTRY OR ANY THIRD PARTY, RESPECTIVELY.</strong></small></p>
            <p><small>My consent to the terms of this consent form is provided of my own free will without any undue influence from any person whatsoever. I further acknowledge that I am aware that I will be provided with a copy of this signed form, should I request it.</small></p>
            <p><small>My digital signature below indicates my understanding of, and my agreement to comply with, the terms of this form.</small></p>

            <Row>
                <Col sm={12} lg={4}>
                    <Form.Label>Name:</Form.Label>
                    <Form.Control
                        name={generateRandomString(15)}
                        type='text'
                        autoComplete="off"
                        value={ field.answer['Name'] ? field.answer['Name'] : '' }
                        onChange={ (e) => {
                            field.answer['Name'] = e.target.value;
                            setFields({...fields, field});
                            digitalSignature(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } }
                    />
                </Col>
                <Col sm={12} lg={4}>
                    <Form.Label>Surname:</Form.Label>
                    <Form.Control
                        name={generateRandomString(15)}
                        type='text'
                        autoComplete="off"
                        value={ field.answer['Surname'] ? field.answer['Surname'] : '' }
                        onChange={ (e) => {
                            field.answer['Surname'] = e.target.value;
                            setFields({...fields, field});
                            digitalSignature(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } }
                    />
                </Col>
                <Col sm={12} lg={4}>
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                        name={generateRandomString(15)}
                        type='date'
                        autoComplete="off"
                        max={currentDate}
                        value={ field.answer['Date'] ? field.answer['Date'] : '' }
                        onChange={ (e) => {
                            field.answer['Date'] = e.target.value;
                            setFields({...fields, field});
                            digitalSignature(fields, setFields, activeField, setActiveField, setRegret, Questions, setCustomHTML, setFileUploading);
                        } }
                    />
                </Col>
            </Row>

        </div>
    );
}