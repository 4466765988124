import React from 'react';
import './App.scss';

import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Body from './components/layout/Body';

function App() {
  return (
    <div className="App">
      <Header title="SABMR FORM" />
      <Body />
      <Footer />
    </div>
  );
}

export default App;
