import React from 'react';
import * as Yup from 'yup';
import { submitToServer } from '../../utils/general';
import { Form, Button, Row, Col, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';


var validationSchema = Yup.object().shape({
  email: Yup.string().when(['drm_code'], {
    is: (drm_code) => !drm_code,
    then: Yup.string().email().required("Email or DRM Code Required!")
  }),
  drm_code: Yup.string().when(['email'], {
    is: (email) => !email,
    then: Yup.string().required("Email or DRM Code Required!")
  })
}, [['email', 'drm_code']]) // <-- HERE!!!!!!!!
 

class RetrieveForm extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			email 			: '',
			drm_code 		: '',
			retrieval		: false
		}
	}

	handleChange = (e, setFieldValue) => {

     	let key     = e.target.id;
      	let value   = e.target.value;

      	// Clear the state and then only set the active input state
		this.setState({
			email 		: '',
			drm_code 	: ''
		}, () => {
			this.setState({
	        	[key] : value
	      	})
		});

      	// Update field value (formik)
      	setFieldValue(key, value);

      	// Remove value from field not active (formik)
      	let inactive = (key === 'email') ? 'drm_code' : 'email';
      	setFieldValue(inactive, '');
   }

	onSubmit = (e, setSubmitting, isValid) => {

		e.preventDefault();

		if(!isValid){
			alert('Either your DRM code or your email address are required!');
			return;
		}

		setSubmitting(true);

		return submitToServer(
			'POST',
			`${process.env.REACT_APP_API_URL}/public/api/retrieve`,
			this.state
		)
		.then(data => {

			if(data.exception){
				console.log(data.message);
			}
			else
			{
				this.setState({ 
					'retrieval' : true
				});
			}

			setSubmitting(false);
		});
   }

	render(){

		const retrieval = <div className="container text-left">
			<Row>
				<Col sm={12} lg={{ span: 4, offset: 4 }} className="form-copy text-center p-4 mt-5">
					<div  className="py-5">
						<h4 className="pb-3">Retrieval Link Sent!!</h4>
						<p>Please check your inbox for the link to your form!</p>
					</div>
				</Col>
			</Row>
		</div>

		const form = <Formik initialValues={this.state} validationSchema={validationSchema} >
			{ 
				({ handleSubmit, handleBlur, values, touched, isSubmitting, isValid, errors, setFieldValue, setSubmitting }) => (

					<div className="container text-left">

						<Form noValidate onSubmit={e => { this.onSubmit(e, setSubmitting, isValid) } }>

							<Row>

								<Col sm={12} lg={{ span: 4, offset: 4 }} className="form-copy text-center p-4 mt-5">	

									{
										(!this.props.loading) &&
											<div>
												
												<h3 className="pb-4">Retrieve Your Form</h3>

												<Form.Group controlId="email">
													<Form.Control name="email" placeholder="Your Email Address" type="text" value={values.email} onChange={ e => { this.handleChange(e, setFieldValue) } } onBlur={handleBlur} isInvalid={errors.email && touched.email} />
													<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
												</Form.Group>
				
													<div className="py-3">OR</div>
				
												<Form.Group controlId="drm_code">
													<Form.Control name="drm_code" placeholder="Your DRM Code" type="text" value={values.drm_code} onChange={ e => { this.handleChange(e, setFieldValue) } } onBlur={handleBlur} isInvalid={errors.drm_code && touched.drm_code} />
													<Form.Control.Feedback type="invalid">{errors.drm_code}</Form.Control.Feedback>
												</Form.Group>
				
												<Button variant="primary" type="submit" disabled={isSubmitting}>

													{
														isSubmitting &&
														<div>
															<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
															&nbsp; &nbsp; processing ...
														</div>
													}
													{
														!isSubmitting &&
															<div>Submit</div>
													}
													
												</Button>
											</div>
									}

									{
										(this.props.loading) &&
											<div>
												<Spinner animation="border" role="status">
													<span className="sr-only">Loading...</span>
												</Spinner>
												<p className="mt-4"><small>Fetching your information, please be patient...</small></p>
											</div>
									}

								</Col>

							</Row>

						</Form>

					</div>

				)
			}
			</Formik>

		return(
			this.state.retrieval ?  retrieval : form
		);
	}
}

export default RetrieveForm;