export const Strings = {
    'success' : `
        <p>Thank you for completing the comprehensive Health Questionnaire. The SABMR Donor Recruitment Team will review your application and advise on your eligibility shortly.</p>
        <p>Should you be eligible, the Team will contact you to make arrangement for delivery and/or completion of the Buccal (cheek) swab completely free of charge.</p>
        <p>Should you wish to change any information submitted on the Health Questionnaire, kindly contact <a href="mailto:donors@sabmr.co.za">donors@sabmr.co.za</a> before continuing.</p>
    `,
    'healthSegment' : `
        <p>Thank you for completing the Health segment of the Questionnaire. The SABMR Donor Recruitment Team will review your application and advise on your eligibility shortly.</p>
        <p>Kindly complete the following biographical information to complete the remaining application</p>
    `,
    'regret' : {
        'defaultMessage' : `<p>Unfortunately the completed health questionnaire indicated a possible risk that might make you not eligible to join the SABMR as a bone marrow stem cell donor.</p>
            <p>If you would like to discuss this further, please contact us at <a href="mailto:donors@sabmr.co.za">donors@sabmr.co.za</a> or telephonically on <a href="tel:+27 21 447 8638">021 447 8638</a>.</p>
            <p>However, we would love to include you in other SABMR initiatives. We therefor kindly request that you stay involved by either making a financial contribution on the website here <a href="https://sabmr.co.za/donate/" target="_blank" rel="noopener">www.sabmr.co.za/donate/</a> or by referring potential donors to apply on our website (<a href="https://www.sabmr.co.za/">www.sabmr.co.za</a>) to become SABMR stem cell donors. Let us know if we may call on you as volunteer during signature events and feel free to contact us on <a href="tel:+27 21 447 8638">021 447 8638</a> (during business hours), should you need to discuss anything.</p>
            <p><strong>Remember: You could still be part of someone’s tomorrow!</strong></p>`,
        'template' : {
            1 : ' - - - ',
            2 : ' - - - '
        },
        'question' : {
            3 : `<p>Unfortunately you do not meet the necessary requirements to become a stem cell donor.  However, you could be most helpful to the SABMR by becoming a financial donor instead.</p>
                    <p>Would you like to make a once-off financial donation?  If you’d like more information on why you were deferred, please have a look at our <a href="#">donor exclusion criteria</a>.</p>
                    <p><a href="http://sabmr.co.za//donate/" class="btn btn-primary">I want to to help with a once-off donation</a></p>
                    <p>Please follow us on <a href="https://www.facebook.com/sabonemreg/" target="_blank">Facebook</a> and <a href="https://www.instagram.com/sabonemreg/" target="_blank">Instagram</a> to stay connected.</p>`,
            4 : `<p>Thank you for taking the time to register and start the application process.</p>
                    <p>Please continue the application process when you have discussed this process, risks and consequences with your immediate family.</p>
                    <p>Kindly contact the SABMR at <a href="mailto:donors@sabmr.co.za">donors@sabmr.co.za</a> should you have any questions or would like to continue with your online application.</p>`
        }
    },
    'locked' : `<div>
        <p>Your form has either already been completed or you have previously been deferred!</p>
        <p>If you suspect an error, please email <a href="mailto:donors@sabmr.co.za">donors@sabmr.co.za</a>.</p>
        <p>Please provide us withy your name, surname and email address as well as any other additional information</p>
        <p><small>(i.e -"I accidentally selected the wrong option whilst completing my online form")</small></p>
    </div>`,
    'termsAndConditions' : `
        <p><strong>IMPORTANT INFORMATION FOR STEM CELL DONORS:</strong><br />
        Any individual is at risk of developing cancer, including leukaemia, lymphoma or other blood diseases throughout his/her lifetime.</p>
        <p>Granulocyte-Colony Stimulating Factor or G-CSF [Neupogen®] stimulates normal blood cell growth. In some patients with cancer or abnormal blood cells,
        it has been shown to stimulate leukaemic blood cells.</p>
        <p>Studies following large numbers of unrelated donors have shown that the risk of developing cancer within several years after the use of G-CSF is not
        increased compared to donors not receiving G-CSF. </p>
        <ul>
            <li>I confirm that I am 18 years or older.</li>
            <li>I have understood the information provided. Any questions I asked were answered to my satisfaction.</li>
            <li>I agree to be registered as a blood stem cell donor and confirm I am available as a possible donor for any patient worldwide.</li>
            <li>I certify, therefore, that I have filled in the donor questionnaire to the best of my knowledge and belief.</li>
            <li>I agree to have blood samples taken for HLA tissue typing which will include the following: HLA-A, -B, -C, -DRB1, -DQB1 and in some instances -DPB1 and blood groups.</li>
            <li>I consent to my personal data, which includes my HLA results, being stored by the SABMR, as well as in the electronic database of the SABMR.</li>
            <li>I also consent to this information being sent outside of the borders of the Republic of South Africa in encoded form.</li>
            <li>I agree to have my samples stored for use in further testing, including DNA testing, at the discretion of the SABMR.</li>
            <li>I understand that my personal information will also be submitted to international registries of blood stem cell donors, however, only in encoded form. The data may be used by the SABMR and other registries in other countries in a donor search for patients worldwide.</li>
            <li>I understand and agree that infectious disease marker testing (e.g. Hepatitis, HIV and Cytomegalo Virus) will be performed as required.</li>
            <li>I agree to communicate any changes to my personal information to the SABMR, in order to keep the relevant databases up-to-date.</li>
            <li>I agree to have my personal data used in an unlinked and anonymous format for release to other persons identified by the SABMR for the purpose of ongoing research.</li>
            <li>I realise that there is a point at which a patient will suffer harm if I withdraw my participation as a donor. I acknowledge the fact that committed donors are needed. Should I not be able to donate blood samples or peripheral blood stem cells any longer for any personal or medical reason, I will immediately notify the SABMR of this.</li>
            <li>I am aware of the fact that I have the right to withdraw my participation in the registry for blood stem cell donors at any time and without consequences for me, by notifying the SABMR telephonically or in writing.</li>
        </ul>
        
        <p><strong>By completing this form, I give permission to SABMR to</strong><p>

        <p>collect and hold the personal information on this form, along with any genetic information from my blood testing, on a secure database;
        release encoded personal and genetic information to the SABMR and any other affiliated international bone marrow donor registeries and any
        other transplant centre for the purposes of determining a match with potential bone marrow transplant recipients;
        permit authorised laboratories and / or registries including but not limited to, the SABMR to make use of any of my samples to do further
        extended testing at the request of the SABMR.</p>
        <p>I further agree to the use of de-identified personal information for analyses of HLA variability and the distribution of the various HLA
        combinations in the population. To this aim, I understand that my personal information will be used in encoded form. These analyses carry no risk for me.<p>`
}