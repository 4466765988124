export async function submitToServer( method, endpoint, data, headers = {} ) {
   
   let params = {
      credentials: 'same-origin',
      headers: {
         'Content-type' : 'application/json',
         'Accept'       : 'application/json',
         ...headers
      }
   };

   if(method !== 'GET'){
      params = {
         ...params,
         method: method,
         body: JSON.stringify(data)
      }
   }

   try { 
      
      const response = await fetch(endpoint, params);
      const {status, statusText} = response;
 
      let responseJson = await response.json();
      responseJson.status = status;
      responseJson.statusText = statusText;
      
      return responseJson;

   } catch(error) {
      
      return error;
   }
}

/**
 * Time/Date Helper functions
 */

export const today = new Date(), month = today.getMonth() +1;
export const currentDate  = today.getFullYear() + '-' + (month < 10 ? '0' + month : month )  + '-' + today.getDate();


/**
 * Generate Random/Unique string
 */
export const generateRandomString = (length) => {
   
   let result = '';
   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
   }

   return result;
}