import React from 'react';
import bg from '../../assets/bg.png';

class Header extends React.Component {

	render(){

		return(
	
			<div className="fixedfooter">
			
				<img src={bg} className="img-fluid" alt="" />

			</div>
		);
	}
}

export default Header;