import React from 'react';
import TopNavigation from './navbar';

class Header extends React.Component {

	render(){

		return(
	
			<div className="nav-bar">
				
				<div className="header">
					<div className="container-fluid">
						<TopNavigation />
					</div>
				</div>

			</div>
		);
	}
}

export default Header;